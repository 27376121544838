var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ProtocolFamily, PrivateKeyFormat } from "../index.js";
import { changeMPCPasscode, validateMPCPasscode } from "../core/mpc.js";
import { AddressesImpl, isEvm } from "./addresses.js";
import { PerformanceSensitive } from "../perf.js";
import { DoNotPersistInJSON, RequireAuth } from "../utils.js";
import { logError, logInfo } from "../bugsnag.js";
export class WalletImpl {
    rootContainerID;
    addresses;
    apiProvider;
    constructor(apiProvider, rootContainerID) {
        this.apiProvider = apiProvider;
        this.rootContainerID = rootContainerID;
        this.addresses = AddressesImpl;
        AddressesImpl.initialize(this.apiProvider, this.rootContainerID);
    }
    finalize() {
        AddressesImpl.finalize();
    }
    async validatePasscode(passcode) {
        if (!passcode) {
            const error = new Error("passcode to validate is empty");
            logInfo(error);
            throw error;
        }
        assertPasscodeValid(passcode);
        return await validateMPCPasscode(this.apiProvider.api.ops_key, passcode);
    }
    async changePasscode(currentPasscode, newPasscode) {
        if (!currentPasscode || !newPasscode) {
            const error = new Error("either current or new passcode is empty");
            logInfo(error);
            throw error;
        }
        assertPasscodeValid(currentPasscode);
        assertPasscodeValid(newPasscode);
        return await changeMPCPasscode(this.apiProvider.api.ops_key, currentPasscode, newPasscode);
    }
    async exportKeys(backup, passcode, format = PrivateKeyFormat.RAW) {
        assertPasscodeValid(passcode);
        return await AddressesImpl.exportKeys(backup, passcode, format);
    }
    async createAddress(protocolSpecifier) {
        if (isEvm(protocolSpecifier)) {
            return (await AddressesImpl.newAddress(ProtocolFamily.EVM));
        }
        const error = new Error("unknown protocol specifier: " + protocolSpecifier);
        logError(error);
        throw error;
    }
}
__decorate([
    RequireAuth
], WalletImpl.prototype, "validatePasscode", null);
__decorate([
    PerformanceSensitive,
    RequireAuth
], WalletImpl.prototype, "changePasscode", null);
__decorate([
    PerformanceSensitive
], WalletImpl.prototype, "exportKeys", null);
__decorate([
    PerformanceSensitive
], WalletImpl.prototype, "createAddress", null);
export class NewWalletImpl extends WalletImpl {
    #backup;
    get backup() {
        return this.#backup;
    }
    constructor(api, rootContainerID, backup) {
        super(api, rootContainerID);
        this.#backup = backup;
    }
}
__decorate([
    DoNotPersistInJSON
], NewWalletImpl.prototype, "backup", null);
export function assertPasscodeValid(passcode) {
    if (passcode && passcode.length < 6) {
        const error = new Error("passcode must be at least 6 characters");
        logInfo(error);
        throw error;
    }
}
