var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { runInSandbox } from "tsifrpc";
import "@coinbase/corekms/dist/wasm_exec.js";
import versions from "./versions.js";
const WAAS_CONSUMER_PARTITION = "5";
const globWindow = () => {
    const glob = (typeof window !== 'undefined' ? window : global);
    return glob;
};
export const referringDomain = () => {
    const origin = window.location.ancestorOrigins
        ? window.location.ancestorOrigins[0]
        : new URL(document.referrer).origin;
    if (origin.toLowerCase().includes("derive.xyz")) {
        return "https://lyra.finance";
    }
    return origin;
};
const LOCAL_WALLET_KEY = "WAAS_WEB_WALLET_";
export class MPC {
    static setLocalWallet(walletJSONStr) {
        window.localStorage.setItem(LOCAL_WALLET_KEY, walletJSONStr);
    }
    static getLocalWallet() {
        return window.localStorage.getItem(LOCAL_WALLET_KEY) || "";
    }
    static removeLocalWallet() {
        window.localStorage.removeItem(LOCAL_WALLET_KEY);
    }
    static async CreateRoamingKeyContainerInstance(authData, passcode, backup) {
        return await globWindow().CreateRoamingKeyContainerInstance(authData, WAAS_CONSUMER_PARTITION, passcode, backup);
    }
    static async CreateRoamingKeyContainer(authData, passcode) {
        return await globWindow().CreateRoamingKeyContainer(authData, WAAS_CONSUMER_PARTITION, passcode);
    }
    static async finalize() {
        await globWindow().DeleteCurrentContainer();
    }
    static async SetLogMode(mode) {
        await globWindow().SetLogMode(mode);
    }
    ;
    static async MwInitialize(env) {
        await globWindow().MwInitialize(env, referringDomain());
    }
    static async GetPublicKeyInfo(authData, fullPaths) {
        return await globWindow().GetPublicKeyInfo(authData, fullPaths);
    }
    static async GetPublicKeyInfoWithAttestation(authData, fullPaths) {
        return await globWindow().GetPublicKeyInfoWithAttestation(authData, fullPaths);
    }
    static async PasscodeValidate(authData, passcode) {
        return await globWindow().PasscodeValidate(authData, passcode);
    }
    static async PasscodeChange(authData, currentPasscode, newPasscode) {
        return await globWindow().PasscodeChange(authData, currentPasscode, newPasscode);
    }
    static async SignHashes(authData, req) {
        return await globWindow().SignHashes(authData, req);
    }
    static async ExportRoamingKeyContainerEcdsaXPrivateKey(authData, backup, passcode, fullPaths) {
        return await globWindow().ExportRoamingKeyContainerEcdsaXPrivateKey(authData, backup, passcode, fullPaths);
    }
    static async ExportRoamingKeyContainerEcdsaPrivateKey(authData, backup, passcode, fullPaths) {
        return await globWindow().ExportRoamingKeyContainerEcdsaPrivateKey(authData, backup, passcode, fullPaths);
    }
    static async fetchLogs() {
        return globWindow().GetLastError(200, false);
    }
    static async loadWasm(wasmPathOverride) {
        if (window.MW && window.MPCModule) {
            return;
        }
        const wasmFetch = [
            fetch(wasmPathOverride + "MW.wasm", { mode: 'cors', integrity: versions.mwSha256 }),
            fetch(wasmPathOverride + "MPCEngine.wasm", { mode: 'cors', integrity: versions.mpceSha256 })
        ];
        const MPCLoaded = new Promise(async (resolve, reject) => {
            try {
                const mpcMod = await import("@coinbase/corekms/dist/MPCEngine.js");
                mpcMod.default({
                    instantiateWasm: (imports, successCallback) => {
                        WebAssembly.instantiateStreaming(wasmFetch[1], imports).then(res => {
                            const MWWasm = res;
                            successCallback(MWWasm.instance);
                            window.MW = MWWasm.instance;
                        }).catch(reject);
                        return {};
                    }
                }).then(loadedMod => {
                    window.MPCModule = loadedMod;
                    resolve(loadedMod);
                }).catch(reject);
            }
            catch (exc) {
                reject(exc);
            }
        });
        const go = new Go();
        const goWasm = await WebAssembly.instantiateStreaming(wasmFetch[0], go.importObject);
        await MPCLoaded;
        console.log(`@coinbase/waas-sdk-web version: ${versions.waasSdkWeb}`);
        console.log(`@coinbase/corekms-web version: ${versions.corekms}`);
        const res = go.run(goWasm.instance);
        res
            .then(() => console.error('MPC Engine terminated unexpectedly (this is a bug).'))
            .catch((e) => console.error(`MPC Engine terminated with error: ${e}`));
    }
}
__decorate([
    runInSandbox
], MPC, "setLocalWallet", null);
__decorate([
    runInSandbox
], MPC, "getLocalWallet", null);
__decorate([
    runInSandbox
], MPC, "removeLocalWallet", null);
__decorate([
    runInSandbox
], MPC, "CreateRoamingKeyContainerInstance", null);
__decorate([
    runInSandbox
], MPC, "CreateRoamingKeyContainer", null);
__decorate([
    runInSandbox
], MPC, "finalize", null);
__decorate([
    runInSandbox
], MPC, "SetLogMode", null);
__decorate([
    runInSandbox
], MPC, "MwInitialize", null);
__decorate([
    runInSandbox
], MPC, "GetPublicKeyInfo", null);
__decorate([
    runInSandbox
], MPC, "GetPublicKeyInfoWithAttestation", null);
__decorate([
    runInSandbox
], MPC, "PasscodeValidate", null);
__decorate([
    runInSandbox
], MPC, "PasscodeChange", null);
__decorate([
    runInSandbox
], MPC, "SignHashes", null);
__decorate([
    runInSandbox
], MPC, "ExportRoamingKeyContainerEcdsaXPrivateKey", null);
__decorate([
    runInSandbox
], MPC, "ExportRoamingKeyContainerEcdsaPrivateKey", null);
__decorate([
    runInSandbox
], MPC, "fetchLogs", null);
__decorate([
    runInSandbox
], MPC, "loadWasm", null);
