export function createConsumerWalletServiceClient(handler) {
    return {
        GenerateAddress(request) {
            const path = `v1/addresses:generateAddress`;
            const body = JSON.stringify(request);
            const queryParams = [];
            let uri = path;
            if (queryParams.length > 0) {
                uri += `?${queryParams.join("&")}`;
            }
            return handler({
                path: uri,
                method: "POST",
                body,
            }, {
                service: "ConsumerWalletService",
                method: "GenerateAddress",
            });
        },
        ListAddresses(request) {
            const path = `v1/addresses`;
            const body = null;
            const queryParams = [];
            if (request.parent) {
                queryParams.push(`parent=${encodeURIComponent(request.parent.toString())}`);
            }
            if (request.protocolFamily) {
                queryParams.push(`protocolFamily=${encodeURIComponent(request.protocolFamily.toString())}`);
            }
            if (request.pageSize) {
                queryParams.push(`pageSize=${encodeURIComponent(request.pageSize.toString())}`);
            }
            if (request.pageToken) {
                queryParams.push(`pageToken=${encodeURIComponent(request.pageToken.toString())}`);
            }
            let uri = path;
            if (queryParams.length > 0) {
                uri += `?${queryParams.join("&")}`;
            }
            return handler({
                path: uri,
                method: "GET",
                body,
            }, {
                service: "ConsumerWalletService",
                method: "ListAddresses",
            });
        },
    };
}
