'use client'

import { SECONDS_IN_MINUTE } from '@lyra/core/constants/time'
import { COINBASE_WAAS_WALLET_CLIENT_TYPE } from '@lyra/web/constants/waas'
import AuthErrorModal from '@lyra/web/containers/common/AuthErrorModal'
import TermsOfUseModal from '@lyra/web/containers/common/TermsOfUseModal'
import WaasDeprecationModal from '@lyra/web/containers/common/WaasDeprecationModal'
import useAdmin from '@lyra/web/hooks/useAdmin'
import {
  getAccessToken as getAccessTokenPrivy,
  useLogin,
  useLogout,
  usePrivy,
} from '@privy-io/react-auth'
import spindl from '@spindl-xyz/attribution'
import React, { useCallback, useEffect, useMemo } from 'react'
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr'
import { Address, getAddress } from 'viem'

import { Auth, NO_AUTH } from '../../constants/auth'
import emptyFunction from '../../utils/emptyFunction'
import WaasProvider from './WaasProvider'

// Poll auth every 5 mins
const AUTH_POLLING_INTERVAL_MS = SECONDS_IN_MINUTE * 5 * 1000

type Props = {
  auth: Auth
  children?: React.ReactNode
}

export type AuthContext = {
  isAuthenticating: boolean
  mutate: KeyedMutator<Auth>
  login: () => void
  logout: () => Promise<void>
  acknowledgeTerms: () => Promise<void>
  setUsername: (username?: string) => Promise<void>
  createMockSessionDONOTUSE: (address: Address) => Promise<void>
  deleteMockSessionDONOTUSE: () => Promise<void>
} & Auth

export const AuthContext = React.createContext<AuthContext>({
  ...NO_AUTH,
  isAuthenticating: false,
  login: emptyFunction as any,
  logout: emptyFunction as any,
  mutate: emptyFunction as any,
  acknowledgeTerms: emptyFunction as any,
  setUsername: emptyFunction as any,
  createMockSessionDONOTUSE: emptyFunction as any,
  deleteMockSessionDONOTUSE: emptyFunction as any,
})

const fetchAuth = async (ownerAddress: Address): Promise<Auth> => {
  const token = await getAccessTokenPrivy()
  if (!token) {
    return NO_AUTH
  }

  const res = await fetch('/api/auth/me')

  if (!res.ok) {
    throw new Error('Failed to authenticate wallet')
  }

  const newAuth = (await res.json()) as Auth
  // Note: need to check returned auth matches client owner address
  // server/client can get out of sync
  if (newAuth.isAuthenticated && newAuth.user.ownerAddress === ownerAddress) {
    return newAuth
  } else {
    return NO_AUTH
  }
}

export default function AuthProvider({ auth: initialAuth, children }: Props) {
  const { isAdmin } = useAdmin()

  const { ready: isPrivyReady, user: privyUser, getAccessToken } = usePrivy()

  const authenticatedOwnerAddress: Address | null = initialAuth.mock
    ? initialAuth.user.ownerAddress
    : isPrivyReady
    ? // Note: if user isMaybeAuthenticated, the privyUser.wallet.address change will trigger
      // mutate() which refreshes the access token
      privyUser?.wallet
      ? getAddress(privyUser.wallet.address)
      : null
    : initialAuth.isAuthenticated
    ? initialAuth.user.ownerAddress
    : null

  const {
    data: auth,
    mutate,
    error,
    isLoading,
  } = useSWR<Auth, Error, [string, Address | null], { fallbackData: Auth } & SWRConfiguration>(
    ['Auth', authenticatedOwnerAddress],
    () => (authenticatedOwnerAddress ? fetchAuth(authenticatedOwnerAddress) : NO_AUTH),
    {
      fallbackData:
        (initialAuth.isAuthenticated &&
          authenticatedOwnerAddress === initialAuth.user.ownerAddress) ||
        initialAuth.isMaybeAuthenticated
          ? initialAuth
          : NO_AUTH,
      refreshInterval: AUTH_POLLING_INTERVAL_MS,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      shouldRetryOnError: false,
    }
  )

  // authenticating for the first time, or determining if user is authenticated
  const isAuthenticating =
    (!auth.isAuthenticated && !!authenticatedOwnerAddress && isLoading) || auth.isMaybeAuthenticated

  const isAuthenticated = auth.isAuthenticated
  const ownerAddress = auth.user?.ownerAddress
  const address = auth.user?.address
  const acknowledgedTerms = !!auth.user?.acknowledgedTerms

  // track session changes on Spindl
  useEffect(() => {
    if (ownerAddress && address && process.env.NEXT_PUBLIC_SPINDL_API_KEY) {
      try {
        spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
        spindl.track('sp_proxy_address', {}, { address, customerUserId: ownerAddress })
      } catch (err) {
        console.warn('Spindl wallet attribution failed')
      }
    }
  }, [ownerAddress, address])

  const { logout: _logout } = useLogout()

  const logout = useCallback(async () => {
    console.debug('privy: logout')

    // unset auth, do not revalidate (triggers flicker)
    mutate(NO_AUTH, { revalidate: false })

    if (privyUser) {
      // delete privy session locally
      await _logout()
    }
  }, [_logout, privyUser, mutate])

  const { login: _login } = useLogin()

  const login = useCallback(async () => {
    const token = await getAccessToken()
    if (token) {
      // mutate auth
      const auth = await mutate()
      if (!auth?.isAuthenticated) {
        // logout user if they are not authenticated after mutation -- allow them to try again
        await logout()
      }
    } else {
      // authenticate
      _login()
    }
  }, [_login, mutate, getAccessToken, logout])

  const setUsername = useCallback(
    async (username?: string) => {
      if (!auth) {
        throw new Error('Not authenticated')
      }

      if (username) {
        const res = await fetch('/api/auth/username', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        })

        if (!res.ok) {
          throw new Error(await res.text())
        }
      } else {
        const res = await fetch('/api/auth/username', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!res.ok) {
          throw new Error(await res.text())
        }
      }

      await mutate()
    },
    [auth, mutate]
  )

  const acknowledgeTerms = useCallback(async () => {
    const res = await fetch('/api/acknowledge-terms', { method: 'POST' })

    if (!res.ok) {
      throw new Error(await res.text())
    }
  }, [])

  const createMockSessionDONOTUSE = useCallback(
    async (address: Address) => {
      if (!isAdmin) {
        throw new Error('Not signed in as admin')
      }

      const res = await fetch('/api/intern/admin/mock-auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      })

      if (!res.ok) {
        throw new Error('Failed to mock')
      }

      // Trigger full reload so initialAuth is populated with mocked auth
      window.location.reload()
    },
    [isAdmin]
  )

  const deleteMockSessionDONOTUSE = useCallback(async () => {
    const res = await fetch('/api/intern/admin/mock-auth', {
      method: 'DELETE',
    })

    if (!res.ok) {
      throw new Error('Failed to unmock')
    }

    // Trigger full reload so initialAuth is NOT populated with mocked auth
    window.location.reload()
  }, [])

  const value = useMemo(() => {
    return {
      ...auth,
      isAuthenticating,
      login,
      logout,
      mutate,
      acknowledgeTerms,
      setUsername,
      createMockSessionDONOTUSE,
      deleteMockSessionDONOTUSE,
    }
  }, [
    auth,
    isAuthenticating,
    login,
    logout,
    mutate,
    acknowledgeTerms,
    setUsername,
    createMockSessionDONOTUSE,
    deleteMockSessionDONOTUSE,
  ])

  // TODO @earthtojake deprecate waas
  const isDeprecatedCoinbaseMpc = useMemo(() => {
    if (!privyUser) {
      return false
    }
    const wallet = privyUser.linkedAccounts.find((acc) => acc.type === 'wallet')
    if (!wallet) {
      return false
    }
    // user signed in with a Coinbase MPC wallet
    return wallet.walletClientType === COINBASE_WAAS_WALLET_CLIENT_TYPE
  }, [privyUser])

  // TODO @earthtojake deprecate waas
  const isNewEmailAccount = useMemo(() => {
    if (!privyUser) {
      return false
    }

    const wallet = privyUser.linkedAccounts.find((acc) => acc.type === 'wallet')
    return !wallet
  }, [privyUser])

  return (
    <AuthContext.Provider value={value}>
      {!isAuthenticated && error ? (
        <AuthErrorModal onClose={logout} />
      ) : isNewEmailAccount ? (
        <AuthErrorModal
          errorMessage="Email and Google account login has been deprecated. Please sign in using a self-hosted wallet like MetaMask, Coinbase Wallet or Rabby."
          onClose={logout}
        /> // Needs to be in TransactionProvider for WaasProvider context
      ) : value.isAuthenticated && isDeprecatedCoinbaseMpc ? (
        // Only mount WaasProvider for users that need to export a wallet
        <WaasProvider>
          <WaasDeprecationModal />
        </WaasProvider>
      ) : isAuthenticated && !acknowledgedTerms ? (
        // SUPER IMPORTANT!! must enforce terms of use modal globally and as top priority
        <TermsOfUseModal />
      ) : null}
      {children}
    </AuthContext.Provider>
  )
}
