import Bugsnag from "@bugsnag/js";
import versions from "./versions.js";
import { MPC } from "./mpc.js";
export async function initBugsnag(bugSnagApiKey) {
    Bugsnag.start({
        apiKey: bugSnagApiKey,
        endpoints: {
            notify: "https://exceptions.coinbase.com",
            sessions: "https://sessions.coinbase.com"
        },
        releaseStage: process.env.WAAS_ENV,
        appVersion: versions.waasSdkWeb,
        autoDetectErrors: true
    });
    Bugsnag.addMetadata("versionInfo", {
        waas_sdk_version: versions.waasSdkWeb,
        corekms_sdk_version: versions.corekms
    });
}
export function logInfo(error, data) {
    Bugsnag.notify(error, (event) => {
        if (data) {
            const { context, metadata } = data;
            if (metadata) {
                metadata.map(tab => event.addMetadata(tab.name, tab.data));
            }
            event.context = context;
        }
        event.severity = "info";
    });
}
export function logWarning(error, data) {
    Bugsnag.notify(error, (event) => {
        if (data) {
            const { context, metadata } = data;
            if (metadata) {
                metadata.map(tab => event.addMetadata(tab.name, tab.data));
            }
            event.context = context;
        }
        event.severity = "warning";
    });
}
export async function logError(error, data) {
    return new Promise((resolve, reject) => {
        const notify = (logs) => {
            Bugsnag.notify(error, (event) => {
                if (data) {
                    const { context, metadata } = data;
                    if (metadata) {
                        metadata.map(tab => event.addMetadata(tab.name, tab.data));
                    }
                    event.context = context;
                }
                event.addMetadata("mpc_logs", { 'logs': logs });
                event.severity = "error";
            });
            resolve();
        };
        try {
            let didTimeout = false;
            let didFetch = false;
            const timeout = setTimeout(() => {
                if (didFetch)
                    return;
                didTimeout = true;
                notify("<failed to fetch logs>");
            }, 3000);
            MPC.fetchLogs().then(logs => {
                didFetch = true;
                if (didTimeout)
                    return;
                clearTimeout(timeout);
                notify(logs);
            }).catch(e => {
                if (didTimeout) {
                    console.error("log fetch timed out.");
                    return;
                }
                notify('<failed to fetch logs>');
            });
        }
        catch (exc) {
            reject(exc);
        }
    });
}
