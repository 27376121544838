import { PrivateKeyFormat } from "../index.js";
import { MPC } from "../mpc.js";
export const MPC_NO_ERROR = "null";
export const EVM_PATH_PREFIX = "44'/60'/0'/0/";
export const EVM_PATH_INDEX_0 = "44'/60'/0'/0/0";
export async function createMPCWallet(authData, passcode = "") {
    let res, model;
    try {
        res = await MPC.CreateRoamingKeyContainer(authData, passcode);
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("error creating the wallet", {
            cause: error
        });
    }
    if (model.err !== MPC_NO_ERROR || model.value === "") {
        throw new Error("MPC error occurred creating the wallet: " + model.err, {
            cause: model
        });
    }
    return model.value;
}
export async function restoreMPCWallet(authData, passcode = "", backup) {
    let res, model;
    try {
        res = await MPC.CreateRoamingKeyContainerInstance(authData, passcode, backup);
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("error restoring the wallet", {
            cause: error
        });
    }
    if (model.err !== MPC_NO_ERROR) {
        throw new Error("MPC error occurred restoring the wallet: " + model.err, {
            cause: parseMPCPasscodeModel(model)
        });
    }
    return;
}
export async function getMPCPublicKeyInfo(authData, fullPaths) {
    let res, model, pubkeyInfo;
    try {
        res = await MPC.GetPublicKeyInfo(authData, fullPaths);
        model = JSON.parse(res);
        pubkeyInfo = model.value;
    }
    catch (error) {
        throw new Error("error getting public key info", {
            cause: error
        });
    }
    if (model.err !== MPC_NO_ERROR ||
        !pubkeyInfo ||
        pubkeyInfo.rootContainerID === "" ||
        pubkeyInfo.rootKeyObjectID === "" ||
        pubkeyInfo.xpub === "" ||
        pubkeyInfo.pubKey === "") {
        throw new Error("MPC error occurred getting public key info: " + model.err, {
            cause: model
        });
    }
    return pubkeyInfo;
}
export async function getMPCPublicKeyAttestation(authData, fullPath) {
    let res, model, signedPubKeyInfo;
    try {
        res = await MPC.GetPublicKeyInfoWithAttestation(authData, fullPath);
        model = JSON.parse(res);
        signedPubKeyInfo = model.value;
    }
    catch (error) {
        throw new Error("error getting public key info with attestation", {
            cause: error
        });
    }
    if (model.err !== MPC_NO_ERROR || !signedPubKeyInfo) {
        throw new Error("MPC error occurred getting public key info with attestation: " +
            model.err, {
            cause: model
        });
    }
    return btoa(JSON.stringify(signedPubKeyInfo));
}
export async function validateMPCPasscode(authData, passcode) {
    let res, model;
    try {
        res = await MPC.PasscodeValidate(authData, passcode || "");
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("MPC error validating passcode", {
            cause: error
        });
    }
    return parseMPCPasscodeModel(model);
}
export async function changeMPCPasscode(authData, currentPasscode, newPasscode) {
    let res, model;
    try {
        res = await MPC.PasscodeChange(authData, currentPasscode, newPasscode);
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("MPC error changing passcode", {
            cause: error
        });
    }
    return parseMPCPasscodeModel(model);
}
export async function signHashes(authData, ...requests) {
    let res, model, signatureResp, s;
    const req = JSON.stringify(requests);
    try {
        res = await MPC.SignHashes(authData, req);
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("error signing hashes", {
            cause: {
                error,
                details: res
            }
        });
    }
    if (model.err !== MPC_NO_ERROR || model.value === "") {
        throw new Error("MPC error occurred signing hashes: " + model.err, {
            cause: model
        });
    }
    signatureResp = model.value;
    s = signatureResp.SignaturesData[0];
    return s;
}
export async function exportMPCKeys(authData, backup, passcode, fullPath, format = PrivateKeyFormat.RAW) {
    if (format === PrivateKeyFormat.RAW) {
        return await exportRawPrivateKey(authData, backup, passcode, fullPath);
    }
    return await exportXPRVPrivateKey(authData, backup, passcode, fullPath);
}
async function exportRawPrivateKey(authData, backup, passcode, fullPath) {
    let res, model;
    try {
        res = await MPC.ExportRoamingKeyContainerEcdsaPrivateKey(authData, backup, passcode, fullPath);
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("error exporting raw private keys", {
            cause: error
        });
    }
    if (model.err !== MPC_NO_ERROR) {
        throw new Error("MPC error occurred exporting raw private keys: " + model.err, {
            cause: parseMPCPasscodeModel(model)
        });
    }
    return model.value;
}
async function exportXPRVPrivateKey(authData, backup, passcode, fullPath) {
    let res, model;
    try {
        res = await MPC.ExportRoamingKeyContainerEcdsaXPrivateKey(authData, backup, passcode, fullPath);
        model = JSON.parse(res);
    }
    catch (error) {
        throw new Error("error exporting extended private keys", {
            cause: error
        });
    }
    if (model.err !== MPC_NO_ERROR) {
        throw new Error("MPC error occurred exporting extended private keys: " + model.err, {
            cause: parseMPCPasscodeModel(model)
        });
    }
    return { xPrivateKey: model.value, keyPath: fullPath };
}
function parseMPCPasscodeModel(model) {
    const passcodePrompt = {
        status: model.err === MPC_NO_ERROR,
        remainingAttempts: model.value?.errorsRemain || model.value || 0,
        message: model.err === MPC_NO_ERROR ? "success" : model.err,
        details: model.err === MPC_NO_ERROR ? {} : model.err_obj
    };
    return passcodePrompt;
}
