import versions from '../versions.js';
export class HttpRequestHandler {
    #hostUrl;
    #serviceName;
    #authToken;
    #verbose;
    constructor(hostUrl, serviceName, authToken, verbose = true) {
        this.#serviceName = serviceName;
        this.#hostUrl = hostUrl;
        this.#authToken = authToken;
        this.#verbose = verbose;
    }
    requestHandler = async (request, meta) => {
        const fullUrl = `${this.#hostUrl}/${request.path}`;
        let authString = "";
        if (this.#authToken) {
            authString = `Bearer ${this.#authToken}`;
        }
        const correlationData = {
            "waas-web-version": versions.waasSdkWeb,
            "corekms-version": versions.corekms,
        };
        const trackingData = Object.keys(correlationData)
            .map((key) => {
            return `${key}=${encodeURIComponent(correlationData[key])}`;
        })
            .join(",");
        let response;
        try {
            response = await fetch(fullUrl, {
                method: request.method,
                body: request.body,
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Correlation-Context": trackingData,
                    Authorization: authString
                }
            });
        }
        catch (e) {
            console.error("Error fetching url: " + fullUrl + " error: " + e);
        }
        if (!response || !response.ok) {
            let errorMessage = `HTTP error! Status: ${response?.status}`;
            try {
                const responseBody = await response?.text();
                if (responseBody) {
                    errorMessage += ` - ${responseBody}`;
                }
            }
            catch (error) {
                console.error("Error parsing response body:", error);
            }
            if (this.#verbose)
                console.error("Request details:", {
                    url: fullUrl,
                    method: request.method,
                    body: request.body,
                    meta: meta,
                    message: errorMessage
                });
            throw new Error(errorMessage, { cause: { status: response?.status } });
        }
        return await response.json();
    };
}
