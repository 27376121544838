import { CoinbaseWaasServerTokenResponse } from '@lyra/web/app/api/auth/waas/token/route'
import { WaasBackup } from '@lyra/web/constants/waas'

// TODO @michaelxuwu DEPRECATE
export const fetchAuthServerToken = async (): Promise<string> => {
  const res = await fetch('/api/auth/waas/token', { method: 'POST', cache: 'no-cache' })

  if (res.status === 403) {
    return ''
  }

  const tokenRes = (await res.json()) as CoinbaseWaasServerTokenResponse
  return tokenRes.token ?? ''
}

// Note: this API endpoint is rate limited by Privy, do not poll
export const fetchBackupClient = async (): Promise<WaasBackup> => {
  const res = await fetch(`/api/auth/waas/backup`)
  if (!res.ok) {
    throw new Error(await res.text())
  }
  const backup = (await res.json()) as WaasBackup
  return backup
}

export const isIncorrectPasswordError = (error: any) => {
  return error instanceof Error && error.message.includes('wrong_passcode')
}
