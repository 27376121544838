import { Address } from 'viem'

import { isMainnet, isTestnet } from './env'
import { baseUrl, MAINNET_APP_URL, TESTNET_APP_URL } from './urls'

export type WaasBackup = {
  ownerAddress: Address
  backup: string
  domain: string
  isProd: boolean

  // Privy user id
  privyDid?: string
  // Privy app id (for dev vs prod environments)
  privyAppId?: string

  // DEPRECATED
  // Email associated with wallet
  email?: string
  // DEPRECATED
  // Firebase user id
  uid?: string // firebase user id
}

/**
 * Enable Coinbase WaaS prod flag (domain protection) if:
 * - Mainnet production deploy or
 * - Testnet production deploy
 * */
export const isWaasProd =
  (isMainnet && baseUrl === MAINNET_APP_URL) || (isTestnet && baseUrl === TESTNET_APP_URL)

export const COINBASE_WAAS_WALLET_CLIENT_TYPE = 'coinbase'
