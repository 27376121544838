var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AddressesImpl_1;
import { ProtocolFamily, PrivateKeyFormat } from "../index.js";
import { PerformanceSensitive } from "../perf.js";
import { StaticImplements, RequireAuth } from "../utils.js";
import { getMPCPublicKeyInfo, getMPCPublicKeyAttestation, signHashes, EVM_PATH_PREFIX, EVM_PATH_INDEX_0, exportMPCKeys } from "../core/mpc.js";
import { logError } from "../bugsnag.js";
let AddressesImpl = class AddressesImpl {
    static { AddressesImpl_1 = this; }
    static apiProvider;
    static _rootContainerID;
    static _addressIndex;
    static _addresses;
    static get api() {
        return this.apiProvider.api;
    }
    static initialize(api, rootContainerID) {
        this.apiProvider = api;
        this._rootContainerID = rootContainerID;
        this._addressIndex = 0;
        this._addresses = new Map();
    }
    static finalize() {
        this._rootContainerID = "";
        this._addressIndex = 0;
        this._addresses?.clear();
        this.apiProvider = undefined;
    }
    static async for(protocolSpecifier) {
        if (isEvm(protocolSpecifier)) {
            return (await this.getAddress(protocolSpecifier, 0));
        }
        const error = new Error("unknown protocol specifier: " + protocolSpecifier);
        logError(error);
        throw error;
    }
    static async all() {
        const pubkeyInfo = await getMPCPublicKeyInfo(this.api.ops_key, EVM_PATH_INDEX_0);
        const rootContainerID = pubkeyInfo.rootContainerID;
        if (this._rootContainerID && this._rootContainerID !== rootContainerID) {
            const error = new Error("rootContainerID mismatch; expected " +
                rootContainerID +
                " but got " +
                this._rootContainerID);
            logError(error);
            throw error;
        }
        let listAddressesResponse = await this.api.cws.listAddresses("wallets/" + rootContainerID, ProtocolFamily.EVM);
        AddressesImpl_1.syncAddresses(listAddressesResponse.addresses);
        while (listAddressesResponse.nextPageToken) {
            listAddressesResponse = await this.api.cws.listAddresses("wallets/" + rootContainerID, ProtocolFamily.EVM, listAddressesResponse.nextPageToken);
            AddressesImpl_1.syncAddresses(listAddressesResponse.addresses);
        }
        this._addressIndex = listAddressesResponse.totalSize;
        return Array.from(this._addresses.values());
    }
    static lock = Promise.resolve();
    static async newAddress(protocolFamily) {
        const unlock = AddressesImpl_1.lock;
        AddressesImpl_1.lock = new Promise(async (resolve, reject) => {
            await unlock;
            const index = AddressesImpl_1._addressIndex;
            AddressesImpl_1._addressIndex = index + 1;
            try {
                const newAddr = await this.getAddress(protocolFamily, index);
                resolve(newAddr);
            }
            catch (e) {
                AddressesImpl_1._addressIndex = index;
                reject(new Error("Unable to generate new address: " + e.message));
            }
        });
        return AddressesImpl_1.lock;
    }
    static async getAddress(protocolFamily, addressIndex) {
        const fullPath = getFullDerivationPath(addressIndex);
        if (this._addresses.has(fullPath)) {
            return this._addresses.get(fullPath);
        }
        try {
            const pubkeyInfo = await getMPCPublicKeyInfo(this.api.ops_key, fullPath);
            const rootContainerID = pubkeyInfo.rootContainerID;
            const pubKeyAttestation = await getMPCPublicKeyAttestation(this.api.ops_key, fullPath);
            if (this._rootContainerID && this._rootContainerID !== rootContainerID) {
                const error = new Error("rootContainerID mismatch; expected " +
                    rootContainerID +
                    " but got " +
                    this._rootContainerID);
                logError(error);
                throw error;
            }
            const cwsAddress = await this.api.cws.generateAddress(this._rootContainerID, pubkeyInfo.rootKeyObjectID, pubkeyInfo.xpub, pubkeyInfo.pubKey, pubKeyAttestation, fullPath, protocolFamily);
            const addr = new AddressImpl(AddressesImpl_1, protocolFamily, fullPath, cwsAddress.address);
            AddressesImpl_1._addresses.set(fullPath, addr);
            return addr;
        }
        catch (e) {
            const error = new Error("Unable to create address:" + e.message, {
                cause: { status: e.cause?.status }
            });
            logError(error);
            throw error;
        }
    }
    static async exportKeys(backup, passcode, format = PrivateKeyFormat.RAW) {
        const privateKeys = [];
        for (const [, addr] of this._addresses) {
            const privateKey = await addr.exportKey(backup, passcode, format);
            privateKeys.push(privateKey);
        }
        return privateKeys;
    }
    static syncAddresses(cwsAddresses) {
        if (!cwsAddresses) {
            return;
        }
        for (const address of cwsAddresses) {
            const fullPath = address.bip32FullDerivationPath;
            this._addresses.set(fullPath, new AddressImpl(AddressesImpl_1, ProtocolFamily.EVM, fullPath, address.address));
        }
    }
};
__decorate([
    RequireAuth
], AddressesImpl, "for", null);
__decorate([
    RequireAuth
], AddressesImpl, "all", null);
__decorate([
    RequireAuth
], AddressesImpl, "newAddress", null);
__decorate([
    RequireAuth
], AddressesImpl, "getAddress", null);
__decorate([
    RequireAuth
], AddressesImpl, "exportKeys", null);
AddressesImpl = AddressesImpl_1 = __decorate([
    StaticImplements()
], AddressesImpl);
export { AddressesImpl };
export class AddressImpl {
    #apiSource;
    protocolFamily;
    fullPath;
    address;
    constructor(apiSource, protocolFamily, fullPath, address) {
        this.#apiSource = apiSource;
        this.protocolFamily = protocolFamily;
        this.fullPath = fullPath;
        this.address = address;
    }
    static lock = Promise.resolve();
    async sign(messageHex) {
        await AddressImpl.lock;
        let unlock;
        AddressImpl.lock = new Promise(resolve => {
            unlock = resolve;
        });
        try {
            const payloadBytes = hexToBytes(messageHex);
            const signedPayload = await signHashes(this.#apiSource.api.ops_key, {
                keyPath: this.fullPath,
                hash: payloadBytes
            });
            return {
                payload: messageHex,
                signedPayload: signedPayload.signature,
                r: signedPayload.r,
                s: signedPayload.s,
                v: signedPayload.v
            };
        }
        catch (e) {
            const error = new Error("Unable to sign:" + e.message, {
                cause: { status: e.cause?.status }
            });
            logError(error);
            throw error;
        }
        finally {
            unlock();
        }
    }
    async exportKey(backup, passcode = "", format = PrivateKeyFormat.RAW) {
        const privateKey = await exportMPCKeys(this.#apiSource.api.ops_key, backup, passcode, this.fullPath, format);
        return privateKey;
    }
}
__decorate([
    PerformanceSensitive,
    RequireAuth
], AddressImpl.prototype, "sign", null);
__decorate([
    RequireAuth
], AddressImpl.prototype, "exportKey", null);
export function isEvm(protocolSpecifier) {
    return (typeof protocolSpecifier === "string" &&
        protocolSpecifier === ProtocolFamily.EVM);
}
function hexToBytes(hexStr) {
    let bytes = [];
    for (let i = 0; i < hexStr.length; i += 2) {
        const numericalVal = parseInt(hexStr.substr(i, 2), 16);
        bytes.push(numericalVal);
    }
    return bytes;
}
function getFullDerivationPath(addressIndex) {
    return EVM_PATH_PREFIX + addressIndex;
}
