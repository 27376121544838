import { TextColor } from '@lyra/core/utils/text'
import React from 'react'
import { StackProps, XStack, YStack } from 'tamagui'

import BodyText from '../BodyText'
import LabelText from '../LabelText'
import TooltipText from '../TooltipText'

type Props = {
  label: string
  value?: React.ReactNode
  color?: TextColor | 'cta'
  tooltipTitle?: string
  tooltipTitleSameAsLabel?: boolean
  tooltipContent?: React.ReactNode
  tooltipStayOpenOnHover?: boolean
  rightContent?: React.ReactNode
  isEditorial?: boolean
} & StackProps

const DataWithLabel = ({
  label,
  value,
  color,
  tooltipTitle,
  tooltipTitleSameAsLabel,
  tooltipContent,
  tooltipStayOpenOnHover,
  rightContent,
  isEditorial,
  ...stackProps
}: Props) => {
  return (
    <YStack {...stackProps} gap="$1">
      {tooltipTitle || tooltipContent ? (
        <TooltipText
          label={label}
          tooltipTitle={tooltipTitleSameAsLabel ? label : tooltipTitle}
          tooltipContent={tooltipContent}
          stayOpenOnHover={tooltipStayOpenOnHover}
          width="fit-content"
        />
      ) : (
        <LabelText width="fit-content">{label}</LabelText>
      )}
      <XStack alignItems="center">
        {!!value ? (
          <BodyText size={isEditorial ? 'editorial' : 'lg'} color={color} marginRight="$1">
            {value}
          </BodyText>
        ) : null}
        {rightContent}
      </XStack>
    </YStack>
  )
}

export default DataWithLabel
