var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var WalletsImpl_1;
import { ProtocolFamily } from "../index.js";
import { MPC } from "../mpc.js";
import { RequireAuth, StaticImplements } from "../utils.js";
import { Logout } from "../waas.js";
import { WalletImpl, NewWalletImpl, assertPasscodeValid } from "./wallet.js";
import { EVM_PATH_INDEX_0, createMPCWallet, restoreMPCWallet, getMPCPublicKeyInfo } from "../core/mpc.js";
import { PerformanceSensitive } from "../perf.js";
import { AddressesImpl } from "./addresses.js";
import { logError, logWarning } from "../bugsnag.js";
let WalletsImpl = class WalletsImpl {
    static { WalletsImpl_1 = this; }
    static wallet;
    static api;
    static async create(passcode) {
        assertPasscodeValid(passcode);
        if (WalletsImpl_1.getWallet()) {
            const error = new Error("a valid wallet already exists. you need to log out before creating another");
            logWarning(error);
            throw error;
        }
        try {
            const backup = await createMPCWallet(this.api.ops_key, passcode);
            const pubkeyInfo = await getMPCPublicKeyInfo(this.api.ops_key, EVM_PATH_INDEX_0);
            const newWallet = new NewWalletImpl(this, pubkeyInfo.rootContainerID, backup);
            WalletsImpl_1.setWallet(newWallet);
            await AddressesImpl.getAddress(ProtocolFamily.EVM, 0);
            return newWallet;
        }
        catch (e) {
            const error = new Error("Unable to create wallet: " + e.message, {
                cause: e.cause
            });
            logError(error);
            throw error;
        }
    }
    static async getLocalWalletImpl() {
        const localWalletStr = await MPC.getLocalWallet();
        if (!localWalletStr) {
            return undefined;
        }
        const localWallet = JSON.parse(localWalletStr);
        return new WalletImpl(this, localWallet.rootContainerID);
    }
    static async resume() {
        try {
            const wallet = await this.getLocalWalletImpl();
            if (!wallet) {
                return null;
            }
            try {
                await AddressesImpl.all();
            }
            catch (e) {
                if (e.cause?.status === 404 || e.cause?.status === 400) {
                    await Logout();
                    return null;
                }
                throw e;
            }
            WalletsImpl_1.setWallet(wallet);
            return wallet;
        }
        catch (e) {
            const error = new Error("Unable to resume the wallet: " + e.message, {
                cause: e.cause
            });
            logError(error);
            throw error;
        }
    }
    static async restoreFromBackup(backup, passcode) {
        assertPasscodeValid(passcode);
        if (WalletsImpl_1.getWallet()) {
            const error = new Error("a valid wallet already exists. you need to log out before restoring another wallet");
            logWarning(error);
            throw error;
        }
        try {
            await restoreMPCWallet(this.api.ops_key, passcode, backup);
            const pubkeyInfo = await getMPCPublicKeyInfo(this.api.ops_key, EVM_PATH_INDEX_0);
            const wallet = new WalletImpl(this, pubkeyInfo.rootContainerID);
            WalletsImpl_1.setWallet(wallet);
            await AddressesImpl.all();
            return wallet;
        }
        catch (e) {
            const error = new Error("Unable to restore the wallet: " + e.message, {
                cause: e.cause
            });
            logError(error);
            throw error;
        }
    }
    static finalize() {
        MPC.removeLocalWallet();
        WalletsImpl_1.wallet?.finalize();
        WalletsImpl_1.wallet = undefined;
    }
    static getWallet() {
        return WalletsImpl_1.wallet;
    }
    static setWallet(wallet) {
        if (WalletsImpl_1.getWallet() === undefined) {
            WalletsImpl_1.wallet = wallet;
        }
        MPC.setLocalWallet(JSON.stringify(wallet));
    }
};
__decorate([
    PerformanceSensitive,
    RequireAuth
], WalletsImpl, "create", null);
__decorate([
    PerformanceSensitive,
    RequireAuth
], WalletsImpl, "resume", null);
__decorate([
    PerformanceSensitive,
    RequireAuth
], WalletsImpl, "restoreFromBackup", null);
WalletsImpl = WalletsImpl_1 = __decorate([
    StaticImplements()
], WalletsImpl);
export { WalletsImpl };
