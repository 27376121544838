import versions from "./versions.js";
var isPerfCheckingEnabled = true;
var isVerboseLoggingEnabled = false;
export const PerfEmitter = new EventTarget();
export function setPerfCheckingEnabled(enabled) {
    isPerfCheckingEnabled = enabled;
}
export function setVerboseLoggingEnabled(enabled) {
    isVerboseLoggingEnabled = enabled;
}
export async function initClientAnalytics() {
    const clientAnalytics = await import("client-analytics");
    clientAnalytics.init({
        platform: "web",
        projectName: "waas-sdk-web",
        eventPath: "/amp",
    });
    function sendMetricsToClientAnalytics(detail) {
        const tags = {
            class: detail.class,
            function: detail.fn,
            total_time_taken_ms: detail.timeTakenMs,
            failed: !!detail.error,
            waas_sdk_version: versions.waasSdkWeb,
            corekms_sdk_version: versions.corekms,
            env: process.env.WAAS_ENV,
            origin: window.location.origin
        };
        clientAnalytics.trackMetric(constructPerformanceGaugeMetric(tags, detail.timeTakenMs));
        clientAnalytics.trackMetric(constructPerformanceDistributionMetric(tags, detail.timeTakenMs));
        clientAnalytics.trackMetric(constructPerformanceHistogramMetric(tags, detail.timeTakenMs));
        clientAnalytics.trackMetric(constructMethodCountMetric(tags));
    }
    function constructPerformanceGaugeMetric(tags, timeTakenMs) {
        let performanceMetric = {
            metricName: "waas.sdk.web.performance.metric",
            metricType: clientAnalytics.MetricType.gauge,
            value: timeTakenMs,
        };
        return Object.assign(performanceMetric, { tags: tags });
    }
    function constructPerformanceDistributionMetric(tags, timeTakenMs) {
        let distributionMetric = {
            metricName: "waas.sdk.web.performance.distribution.metric",
            metricType: clientAnalytics.MetricType.distribution,
            value: timeTakenMs,
        };
        return Object.assign(distributionMetric, { tags: tags });
    }
    ;
    function constructPerformanceHistogramMetric(tags, timeTakenMs) {
        let histogramMetric = {
            metricName: "waas.sdk.web.performance.histogram.metric",
            metricType: clientAnalytics.MetricType.histogram,
            value: timeTakenMs,
        };
        return Object.assign(histogramMetric, { tags: tags });
    }
    ;
    function constructMethodCountMetric(tags) {
        let countMetric = {
            metricName: "waas.sdk.web.method.count.metric",
            metricType: clientAnalytics.MetricType.count,
            value: 1,
        };
        return Object.assign(countMetric, { tags: tags });
    }
    PerfEmitter.addEventListener('waas.perf', (event) => {
        const customEvent = event;
        sendMetricsToClientAnalytics(customEvent.detail);
    });
}
export const PerformanceSensitive = (target, propertyKey, descriptor) => {
    const originalMethod = descriptor.value;
    const className = target.name ?? (target?.constructor?.name ? `${target.constructor.name}` : "anonymous::");
    descriptor.value = function (...args) {
        if (!isPerfCheckingEnabled) {
            return originalMethod.apply(this, args);
        }
        const startTime = performance.now();
        let failed = null;
        let result = null;
        try {
            result = originalMethod.apply(this, args);
        }
        catch (exc) {
            failed = exc;
        }
        if (result instanceof Promise) {
            result.catch(exc => {
                failed = exc;
            }).finally(() => {
                const endTime = performance.now();
                const timeTaken = endTime - startTime;
                if (isVerboseLoggingEnabled) {
                    console.log(`${className}::${propertyKey}::(${timeTaken}ms)`);
                }
                PerfEmitter.dispatchEvent(new CustomEvent('waas.perf', {
                    detail: {
                        class: className,
                        fn: propertyKey,
                        timeTakenMs: timeTaken,
                        error: failed,
                    }
                }));
            });
        }
        else {
            const endTime = performance.now();
            const timeTaken = endTime - startTime;
            PerfEmitter.dispatchEvent(new CustomEvent('waas.perf', {
                detail: {
                    class: className,
                    fn: propertyKey,
                    timeTakenMs: timeTaken,
                    error: failed
                }
            }));
            if (isVerboseLoggingEnabled) {
                console.log(`${propertyKey}:(${timeTaken}ms)`);
            }
        }
        if (failed) {
            throw failed;
        }
        return result;
    };
};
