import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import { Eye, EyeOff } from '@lyra/core/components/Icon'
import Input from '@lyra/core/components/Input'
import Link from '@lyra/core/components/Link'
import Modal from '@lyra/core/components/Modal'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import CopyButton from '@lyra/web/components/common/CopyButton'
import { HELP_EXPORT_EMBEDDED_WALLET_PRIVATE_KEY_URL } from '@lyra/web/constants/urls'
import useAuth from '@lyra/web/hooks/useAuth'
import useWaasDEPRECATED from '@lyra/web/hooks/useWaasDEPRECATED'
import { isIncorrectPasswordError } from '@lyra/web/utils/client/waas'
import { usePrivy } from '@privy-io/react-auth'
import { useEffect, useState } from 'react'
import { XStack } from 'tamagui'

export default function WaasDeprecationModal() {
  const { logout } = useAuth()
  const { user } = usePrivy()
  const [error, setError] = useState('')
  const [isRevealed, setIsRevealed] = useState(false)
  const [privateKey, setPrivateKey] = useState('')
  const { exportPrivateKeySENSITIVE, isReady } = useWaasDEPRECATED()
  // SUPER IMPORTANT!! Do NOT log
  const [passwordSENSITIVE, setPassword] = useState<string>('')

  useEffect(() => {
    // flag that user has exported private key
    fetch('/api/auth/waas/exported', { method: 'POST', cache: 'no-cache' })
  }, [privateKey])

  if (!user) {
    return null
  }

  return (
    <Modal title="Export Derive Wallet" disableClose onClose={logout} isLarge>
      <Section.XStack>
        <Notice
          status="warning"
          width="100%"
          message="We are discontinuing support for the Derive wallet feature. To access your funds, you need to export your private key and import it into a self-hosted wallet such as MetaMask, Coinbase Wallet or Rabby. Ensure you complete this step in a private place and store your private key securely."
        />
      </Section.XStack>
      <Section.XStack>
        <BodyText>
          You can find instructions on how to import your private key into a self-hosted
          wallet&nbsp;
          <Link label="here" href={HELP_EXPORT_EMBEDDED_WALLET_PRIVATE_KEY_URL} target="_blank" />
        </BodyText>
      </Section.XStack>
      <Section.XStack>
        <DataWithLabel label="Email" value={user.email?.address ?? 'Unknown'} />
      </Section.XStack>
      <Section.XStack>
        <DataWithLabel
          label="Wallet Address"
          value={user.wallet ? formatTruncatedAddress(user.wallet.address) : '-'}
          rightContent={<CopyButton value={user.wallet?.address ?? ''} size="sm" />}
        />
      </Section.XStack>
      {!privateKey ? (
        <>
          <Section.YStack>
            <Input
              label="Wallet Password"
              isPassword={!isRevealed}
              value={passwordSENSITIVE}
              onChangeValue={setPassword}
              isDisabled={!!privateKey}
              placeholder="Password"
              size="lg"
              rightContent={
                <Button
                  icon={isRevealed ? <EyeOff /> : <Eye />}
                  size="sm"
                  onPress={() => setIsRevealed(!isRevealed)}
                />
              }
            />
          </Section.YStack>
          <Section.YStack>
            {error ? <Notice width="100%" status="error" message={error} /> : null}
            <Button
              size="lg"
              isSolid
              color="red"
              label="Export Private Key"
              isDisabled={!passwordSENSITIVE || !!privateKey}
              onPress={async () => {
                try {
                  const pk = await exportPrivateKeySENSITIVE(passwordSENSITIVE)
                  setPrivateKey(pk.ecKeyPrivate)
                } catch (e) {
                  console.error(e)
                  if (isIncorrectPasswordError(e)) {
                    setError('Incorrect password.')
                    return
                  } else if (e instanceof Error) {
                    setError(e.message)
                  } else {
                    setError('Something went wrong. Contact Lyra for support.')
                  }
                }
              }}
            />
            <Button isLoading={!isReady} size="lg" label={'Disconnect'} onPress={logout} />
          </Section.YStack>{' '}
        </>
      ) : (
        <>
          <Section.YStack flexGrow={1} width="100%">
            <XStack gap="$1" alignItems="flex-end">
              <Input
                label="Private Key"
                size="lg"
                flexGrow={1}
                flexShrink={1}
                isDisabled
                value={privateKey}
              />
              <CopyButton size="lg" value={privateKey} />
            </XStack>
            <Notice
              status="warning"
              message="Never share this key. Anyone with your private key can steal any assets held in your account."
              width="100%"
            />
          </Section.YStack>
          <Section.YStack>
            <Button
              isLoading={!isReady}
              size="lg"
              label={'Disconnect And Continue'}
              onPress={logout}
            />
          </Section.YStack>
        </>
      )}
    </Modal>
  )
}
