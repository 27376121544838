var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var WaasImpl_1;
import { WalletsImpl } from "./wallets/wallets.js";
import { WaasCWS } from "./core/cws.js";
import { StaticImplements } from "./utils.js";
import { AddressesImpl } from "./wallets/addresses.js";
import { MPC } from "./mpc.js";
import { sandboxInit } from "tsifrpc";
import mpcSandboxApiVersion from './mpc.sum.js';
import versions from "./versions.js";
import { PerformanceSensitive, initClientAnalytics, setPerfCheckingEnabled, setVerboseLoggingEnabled } from "./perf.js";
import { initBugsnag, logError, logInfo, logWarning } from "./bugsnag.js";
const LOCAL_SANDBOX_HOST = "https://localhost:8089";
const LOCAL_SANDBOX_URL = `${LOCAL_SANDBOX_HOST}/index.html`;
const LOCAL_COREKMS_URL = `${LOCAL_SANDBOX_HOST}/corekms/`;
const SANDBOX_PATH = `/waas/consumer/assets/waas-sdk-web/${versions.waasSdkWeb}/index.html`;
const COREKMS_WASM_PATH = `/waas/consumer/assets/corekms/${versions.corekms}/`;
const SECURE_SANDBOX_URLS = {
    LOCAL: "http://localhost:10000",
    DEV: "https://secure-waas-dev.cbhq.net",
    STAGING: "https://secure-waas-staging.cbhq.net",
    PROD: "https://secure-waas.coinbase.com",
};
const TESTING_SANDBOX_URLS = {
    LOCAL: "http://localhost:10000",
    DEV: "https://cloud-api-dev.cbhq.net",
    STAGING: "https://cloud-api-staging.cbhq.net",
    PROD: "https://api.developer.coinbase.com"
};
const COREKMS_PATH = "mpc-eco/operations";
const WAAS_PATH = "waas/";
const WAAS_ENV = process?.env?.WAAS_ENV ?? "PROD";
const DEFAULT_REAUTH_TIMEOUT = 100 * 1000;
const BUGSNAG_API_KEY = "b6eb2463abbaf9aa8333637762ba1dd0";
const normalizeUrl = (url) => {
    if (url.charAt(url.length - 1) !== "/") {
        return url + "/";
    }
    return url;
};
export async function InitializeWaas(options) {
    if (process?.env?.USE_LOCAL_SANDBOX) {
        if (options.prod === true) {
            throw new Error("do not use a local sandbox with InitializeWaasOptions.prod enabled. This could cause compatibility or security issues with your wallets.");
        }
    }
    const baseUrl = options.waasUrl ?? getBaseURL(WAAS_ENV, options.prod === true);
    const sandboxPath = options.sandboxPath ?? getSandboxPath(WAAS_ENV, baseUrl);
    const wasmPath = options.wasmPath ?? getWASMPath(WAAS_ENV, baseUrl);
    const collectAndReportMetrics = options.collectAndReportMetrics ?? true;
    const verboseLoggingEnabled = options.verbose === true;
    setPerfCheckingEnabled(collectAndReportMetrics);
    setVerboseLoggingEnabled(verboseLoggingEnabled);
    if (collectAndReportMetrics) {
        await initClientAnalytics();
    }
    await initBugsnag(BUGSNAG_API_KEY);
    sandboxInit({
        sandbox: sandboxPath,
        sandbox_title: "Waas Secure Sandbox",
        version: mpcSandboxApiVersion
    });
    let sandboxOrigin = sandboxPath;
    if (sandboxOrigin.endsWith("index.html")) {
        sandboxOrigin = sandboxOrigin.slice(0, -"index.html".length - 1);
    }
    await WaasImpl.setup(normalizeUrl(baseUrl), normalizeUrl(wasmPath), options);
    try {
        await WalletsImpl.resume();
    }
    catch { }
    return WaasImpl;
}
export async function Logout() {
    await WaasImpl.finalize();
}
let WaasImpl = class WaasImpl {
    static { WaasImpl_1 = this; }
    static wallets = WalletsImpl;
    static cws;
    static provideAuthToken;
    static lastAuthTime = 0;
    static waasURL;
    static async forceRefreshAuthToken() {
        await this.maybeRefreshAuthToken(true);
    }
    static async maybeRefreshAuthToken(force = false) {
        if (!this.cws) {
            const error = new Error("Waas is not initialized yet.");
            logInfo(error);
            throw error;
        }
        if (!this.provideAuthToken) {
            const error = new Error("Waas is not authenticated.");
            logWarning(error);
            throw error;
        }
        if (!force) {
            if ((Date.now() - this.lastAuthTime) < DEFAULT_REAUTH_TIMEOUT) {
                return;
            }
        }
        let authToken;
        try {
            authToken = await this.provideAuthToken();
            this.lastAuthTime = Date.now();
        }
        catch (exc) {
            const error = new Error("Waas failed to refresh auth token. \n\nPlease check the following: \n(1) - Is your auth server running?\n(2) Does navigating to https://localhost:8082 work? (You may need to click through 'Advanced' -> 'Proceed Anyways', if you haven't before.)\n(3) Does your `provideAuthToken` implementation have an issue?\n\n", { cause: exc });
            logWarning(error);
            throw error;
        }
        const auth = parseAuth(authToken);
        const cwsToken = auth.cws;
        const api = {
            cws: this.cws.withAuth(cwsToken),
            cws_key: auth.cws,
            ops_key: auth.ops
        };
        WalletsImpl.api = api;
        AddressesImpl.apiProvider = WalletsImpl;
    }
    static async finalize() {
        await Promise.all([MPC.finalize(), WalletsImpl.finalize()]);
    }
    static async setup(baseUrl, wasmPath, options) {
        WaasImpl_1.waasURL = baseUrl + WAAS_PATH;
        WaasImpl_1.provideAuthToken = options.provideAuthToken;
        WaasImpl_1.cws = new WaasCWS({
            hostname: this.waasURL,
            verbose: options.verbose === true,
            onAuthExpired: WaasImpl_1.forceRefreshAuthToken
        });
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    MPC.loadWasm(wasmPath),
                    this.forceRefreshAuthToken(),
                ]);
                try {
                    await MPC.SetLogMode(WAAS_ENV === 'DEV' ? 'on' : 'error');
                    await MPC.MwInitialize(getCoreKmsUrl(WAAS_ENV, baseUrl));
                }
                catch (e) {
                    const error = new Error("error initializing MPC module: " + e);
                    logError(error);
                    throw error;
                }
                setTimeout(resolve, 50);
            }
            catch (exc) {
                reject(exc);
            }
        });
    }
};
__decorate([
    PerformanceSensitive
], WaasImpl, "setup", null);
WaasImpl = WaasImpl_1 = __decorate([
    StaticImplements()
], WaasImpl);
export { WaasImpl };
const parseAuth = (authToken) => {
    let token;
    try {
        token = JSON.parse(atob(authToken));
    }
    catch {
        throw new Error("Malformed auth token: " + authToken);
    }
    if (!token.cws || !token.ops) {
        throw new Error("Invalid auth token.");
    }
    return token;
};
function getBaseURL(env, useSecureSandbox) {
    if (useSecureSandbox) {
        return SECURE_SANDBOX_URLS[env];
    }
    return TESTING_SANDBOX_URLS[env];
}
function getSandboxPath(env, baseUrl) {
    if (process?.env?.USE_LOCAL_SANDBOX) {
        return LOCAL_SANDBOX_URL;
    }
    return baseUrl + SANDBOX_PATH;
}
function getWASMPath(env, baseUrl) {
    if (process?.env?.USE_LOCAL_SANDBOX) {
        return LOCAL_COREKMS_URL;
    }
    return baseUrl + COREKMS_WASM_PATH;
}
function getCoreKmsUrl(env, baseUrl) {
    if (globalThis.process?.env?.COREKMS_URL_OVERRIDE !== undefined) {
        return globalThis.process?.env.COREKMS_URL_OVERRIDE;
    }
    if (env === "LOCAL") {
        return getBaseURL("DEV", false) + COREKMS_PATH;
    }
    return baseUrl + COREKMS_PATH;
}
