var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as CWS from "../generated/coinbase/cloud/wallets/consumer/v1/index.js";
import { ProtocolFamily } from "../networks.js";
import { HttpRequestHandler } from "./http.js";
const MAX_RETRIES = 3;
const CWServiceName = "waas_consumer_wallet_service";
export class WaasCWS {
    #fullyQualifiedPath;
    #cwsClient;
    #verbose;
    onAuthExpired;
    constructor(options) {
        this.#fullyQualifiedPath = `${options.hostname}consumer/wallets`;
        this.#verbose = options.verbose;
        this.onAuthExpired = options.onAuthExpired;
    }
    withAuth(authToken) {
        const handler = new HttpRequestHandler(this.#fullyQualifiedPath, CWServiceName, authToken, this.#verbose);
        this.#cwsClient = CWS.createConsumerWalletServiceClient(handler.requestHandler);
        return this;
    }
    async generateAddress(keyContainerRootId, keyContainerObjectId, bip32XpubKey, bip32AccountPubKey, bip32XpubKeyAttestation, bip32FullDerivationPath, protocolFamily = ProtocolFamily.EVM) {
        const generateAddressRequest = {
            protocolFamily: protocolFamily,
            keyContainerRootId: keyContainerRootId,
            keyContainerObjectId: keyContainerObjectId,
            bip32XpubKey: bip32XpubKey,
            bip32AccountPubKey: bip32AccountPubKey,
            bip32XpubKeyAttestation: bip32XpubKeyAttestation,
            bip32FullDerivationPath: bip32FullDerivationPath
        };
        return await this.#cwsClient.GenerateAddress(generateAddressRequest);
    }
    async listAddresses(parent, protocolFamily = ProtocolFamily.EVM, pageToken, pageSize) {
        const listAddressesRequest = {
            protocolFamily: protocolFamily,
            parent: parent,
            pageToken: pageToken,
            pageSize: pageSize
        };
        return await this.#cwsClient.ListAddresses(listAddressesRequest);
    }
}
__decorate([
    retryable()
], WaasCWS.prototype, "generateAddress", null);
__decorate([
    retryable()
], WaasCWS.prototype, "listAddresses", null);
function retryable() {
    return function (target, propertyKey, descriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args) {
            const instance = this;
            for (let i = 0; i < MAX_RETRIES; i++) {
                try {
                    return await originalMethod.apply(this, args);
                }
                catch (e) {
                    if (e.cause?.status === 401 && instance.onAuthExpired) {
                        await instance.onAuthExpired();
                    }
                    else {
                        throw e;
                    }
                }
            }
            throw new Error("Max retries reached. Unable to complete request.");
        };
        return descriptor;
    };
}
