import { WaasImpl } from "./waas.js";
export function StaticImplements() {
    return (constructor) => {
        return constructor;
    };
}
export function RequireAuth(target, propertyKey, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args) {
        await WaasImpl.maybeRefreshAuthToken();
        return await originalMethod.apply(this, args);
    };
}
export function DoNotPersistInJSON(target, propertyKey) {
    if (!target.__doNotPersist) {
        target.__doNotPersist = new Set();
        const originalToJSON = target.toJSON;
        target.toJSON = function () {
            const obj = originalToJSON ? originalToJSON.call(this) : { ...this };
            target.__doNotPersist.forEach((key) => {
                delete obj[key];
            });
            return obj;
        };
    }
    target.__doNotPersist.add(propertyKey);
}
