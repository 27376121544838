'use client'

import { WalletProvider as CoinbaseWaasProvider } from '@coinbase/waas-sdk-web-react'
import { isWaasProd } from '@lyra/web/constants/waas'
import React from 'react'

import { fetchAuthServerToken } from '../../../utils/client/waas'
import WaasInternalProvider from './WaasInternalProvider'

type Props = {
  children?: React.ReactNode
}

export default function WaasProvider({ children }: Props) {
  return (
    <CoinbaseWaasProvider
      provideAuthToken={fetchAuthServerToken}
      prod={isWaasProd}
      autoCreateWallet={false}
      collectAndReportMetrics={true}
    >
      <WaasInternalProvider>{children}</WaasInternalProvider>
    </CoinbaseWaasProvider>
  )
}
